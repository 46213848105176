body, html {
  margin:0; padding: 0;
  height: 100%;
    overflow: hidden;
}

body {
  margin: 0;
  width: 100%;
  height: 100vh;
  font-family: "Helvetica Neue",Arial,sans-serif;
  position: relative;
}

.anim{
    transform: scale(1) !important;
}

#root {
    width: 100%;
    height: inherit;
    box-sizing: border-box;
}

.slick-next{
    right: 25px !important;
    z-index: 2;
}
.slick-prev
{
    left: 25px !important;
    z-index: 2;
}
