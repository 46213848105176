.skeleton-image {
    object-fit: contain;
    height: fit-content;
    width: 100%;
    margin-bottom:  2vw;

    transform: scale(0.5);
    transition: all 0.8s ease 0s;
}

.skeleton-image_loading {
    object-fit: contain;
    height: fit-content;
    width: 100%;
    margin-bottom:  2vw;
    background: rgba(255, 255, 255, 0.4);
    z-index: 1;
    animation: skeleton-animation 1.5s infinite linear;
}

@keyframes skeleton-animation {
    0% {
        background-position: -100%;
    }
    100% {
        background-position: 100%;
    }
}
